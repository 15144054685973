import React from "react";
import * as Cookies from "js-cookie";
import { compose } from "recompose";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withFirebase } from "../../service/firebase";
import { withAuthorization } from "../../service/session";
import VideoCall from "../VideoCall";
import { GridContainer, GridItem, If } from "../../common";

import "./meeting.css";

// eslint-disable-next-line react/display-name
class Meeting extends React.Component {
  constructor(props) {
    super(props);
    this.channel = Cookies.get("channel");
    this.channelList = Cookies.get("channelList");
    this.rtmChannel = Cookies.get("rtmChannel");
    this.userAgoraId = Cookies.get("userAgoraId");
    this.tokens = Cookies.get("tokens");
    this.rtmToken = Cookies.get("rtmToken");
    this.ownerId = Cookies.get("ownerId");
    this.eventId = Cookies.get("eventId");
    this.ownerAgoraId = null;
    this.batch = Cookies.get("batch");

    this.state = {
      event: null,
    };
  }

  componentWillMount() {
    this.props.firebase
      .event(this.ownerId, this.eventId)
      .onSnapshot(async (data) => {
        const configuration = (
          await this.props.firebase.getConfiguration()
        ).data();
        const event = data.data();

        const extraTime = configuration.additional_duration_in_mins * 60;
        const waitingSeconds =
          configuration.waiting_lounge_duration_in_mins * 60;

        const serverTime = this.props.firebase.getTimeStamp();
        const isStarted =
          serverTime.seconds > event.eventDate?.seconds - waitingSeconds;
        const isCompleted =
          serverTime.seconds >
          event.eventDate?.seconds + event.eventDate?.eventDuration + extraTime;

        let cameraList = await this.props.firebase.getHostCameras(
          event.eventPath
        );
        cameraList = cameraList.docs.map((camera) => {
          return {
            ticketAgoraId: camera.data().ticketAgoraId,
            displayName: camera.data().attendee.displayName,
            isCamera: camera.data().ticketType !== 'SCREEN_SHARING',
            isShareScreen: camera.data().ticketType === 'SCREEN_SHARING',
          };
        });

        this.props.firebase.getAttendeeList(
          this.ownerId,
          event.eventPath,
          async (data) => {
            const attendeeList = [...data, ...cameraList];

            const currentAttendeUser = attendeeList.find(
              (user) => Number(user.ticketAgoraId) === Number(this.userAgoraId)
            );

            const isUserExist =
              Number(event.eventOwnerAgoraId) === Number(this.userAgoraId) ||
              Boolean(currentAttendeUser);

            if (!isUserExist || !isStarted || isCompleted) {
              this.returnEventsPage();
            } else {
              this.setState({
                event,
                attendeeList,
                configuration,
              });
            }
          },
          (error) => {
            this.returnEventsPage();
          }
        );
      });
    const root = document.querySelector("#root");
    root.classList.add("full");
  }

  componentWillUnmount() {
    const root = document.querySelector("#root");
    root.classList.remove("full");
  }

  returnEventsPage = () => {
    window.location.href = "/events";
  };

  render() {
    return (
      <If
        condition={Boolean(this.state.event)}
        otherwise={() => (
          <GridContainer>
            <GridItem md={12} sm={12} xs={12}>
              <LinearProgress />
              <h6 style={{ textAlign: "center" }}>Loading...</h6>
            </GridItem>
          </GridContainer>
        )}
        render={() => (
          <div className="wrapper meeting">
            <div className="ag-main">
              <VideoCall
                appId="7a4aa2088f7a4451995d2ff1c70e6889"
                attendeeList={this.state.attendeeList}
                channel={this.channel}
                channelList={this.channelList}
                rtmChannel={this.rtmChannel}
                configuration={this.state.configuration}
                event={this.state.event}
                ownerAgoraId={
                  this.state.event && this.state.event.eventOwnerAgoraId
                }
                ownerId={this.ownerId}
                rtmToken={this.rtmToken}
                serverTime={this.state.serverTime}
                tokens={this.tokens}
                userAgoraId={this.userAgoraId}
                firebase={this.props.firebase}
                cameraList={this.state.cameraList}
                meetingState={this.props.meetingState}
                updateMeetingState={this.props.updateMeetingState}
                batch={this.batch}
              />
            </div>
          </div>
        )}
      />
    );
  }
}

const condition = (authUser) => Boolean(authUser);

export default compose(withFirebase, withAuthorization(condition))(Meeting);
