import { useState, useEffect, useCallback, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  VIDEO_PROPERTIES,
  STREAM_FALLBACK_TYPE,
  STREAM_ROLES,
  STREAM_TYPE,
  VIDEO_COMMAND_TYPE
} from "../../../constants/video";
import { testMultiUser } from "../helper/multiUserfunctionality";
import RTMClient from "../../../service/agora/agora-rtm";

const useAgora = (client, logger, firebase, event, batch, appId, clientMessageChannel, rtmChannel, userAgoraId) => {
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [localShareTrack, setLocalShareTrack] = useState();
  const [localShareAudiTrack, setLocalShareAudioTrack] = useState();
  const [otherChannels, setOtherChannels] = useState({});
  const localShareTrackRef = useRef();
  const localShareAudioTrackRef = useRef();
  const [rtcError, setRTCError] = useState();
  const meetingState = useRef();

  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);

  const [remoteUsersStatus, setRemoteUsersStatus] = useState(null);

  const updateHookMeetingState = (currentMeetingState) => {
    meetingState.current = currentMeetingState;
  }

  const createLocalTracks = async (configuration) => {
    const [
      microphoneTrack,
      cameraTrack,
    ] = await AgoraRTC.createMicrophoneAndCameraTracks(
      {
        AEC: true,
        AGC: true,
        ANS: true,
      },
      {
        encoderConfig: {
          width: Number(configuration.videoWidth),
          height: Number(configuration.videoHeight),
          frameRate: Number(configuration.videoFps),
          bitrateMin: Number(configuration.videoBitrateMin),
          bitrateMax: Number(configuration.videoBitrateMax),
        },
      }
    );

    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  };

  const playShareScreen2 = async (isOnlyAudio) => {
    try {
      const screenTracks = await AgoraRTC.createScreenVideoTrack(
        { encoderConfig: "720p_3" },
        "auto"
      );
  
      let screenVideoTrack = screenTracks;
      let screenAudioTrack = null;
      if (screenTracks.length) {
        screenVideoTrack = screenTracks[0];
        screenAudioTrack = screenTracks[1];
      }
  
      if (isOnlyAudio) {
        setLocalShareAudioTrack(screenAudioTrack);
      } else {
        setLocalShareTrack(screenVideoTrack);
      }
  
      localShareTrackRef.current = screenVideoTrack;
  
      if (screenAudioTrack && !isOnlyAudio) {
        localShareAudioTrackRef.current = screenAudioTrack;
        await client.unpublish(localVideoTrack);
        await client.publish([screenVideoTrack, screenAudioTrack]);
      } else if (screenAudioTrack && isOnlyAudio) {
        await client.publish(screenAudioTrack);
      } else if (!isOnlyAudio) {
        await client.unpublish(localVideoTrack);
        await client.publish(screenVideoTrack);
      }
  
      if (screenVideoTrack && !isOnlyAudio) {
        screenVideoTrack.on("track-ended", () => stopShareScreen(userAgoraId));
      }
  
      if (screenAudioTrack) {
        screenAudioTrack.on("track-ended", () => stopShareScreen(userAgoraId));
      }
  
      if (isOnlyAudio && !screenAudioTrack) {
        stopShareScreen(userAgoraId);
      }
    } catch (error) {
      if (error.code === 'PERMISSION_DENIED') {
        setRTCError(error.message);
      }
      logger.error(error);
    }
  };

  const screenShareClient = useRef();
  const ssMessageChannel = useRef();

  const playShareScreen = async (isOnlyAudio) => {
    try {
      const screenTracks = await AgoraRTC.createScreenVideoTrack(
        { encoderConfig: "720p_3" },
        "auto"
      );

      let screenVideoTrack = screenTracks;
      let screenAudioTrack = null;
      if (screenTracks.length) {
        screenVideoTrack = screenTracks[0];
        screenAudioTrack = screenTracks[1];
      }
  
      localShareTrackRef.current = screenVideoTrack;

      const screenShareData = firebase.getScreenShareInfo();
      const ssData = await screenShareData({eventPath : event.eventPath});

      const {agoraId, channelId, rtcToken, rtmToken} = ssData.data;

      screenShareClient.current = AgoraRTC.createClient({
        codec: VIDEO_PROPERTIES.CODEC,
        mode: VIDEO_PROPERTIES.MODE,
        role: STREAM_ROLES.HOST,
      });
  
      await screenShareClient.current.join(
        appId,
        channelId,
        rtcToken,
        Number(agoraId)
      );

      if (screenAudioTrack && !isOnlyAudio) {
        localShareAudioTrackRef.current = screenAudioTrack;
        await screenShareClient.current.publish([screenVideoTrack, screenAudioTrack]);
      } else if (screenAudioTrack && isOnlyAudio) {
        await screenShareClient.current.publish(screenAudioTrack);
      } else if (!isOnlyAudio) {
        await screenShareClient.current.publish(screenVideoTrack);
      }

      if (screenVideoTrack && !isOnlyAudio) {
        screenVideoTrack.on("track-ended", () => stopShareScreen(userAgoraId));
      }
  
      if (screenAudioTrack) {
        screenAudioTrack.on("track-ended", () => stopShareScreen(userAgoraId));
      }
  
      if (isOnlyAudio && !screenAudioTrack) {
        stopShareScreen(userAgoraId);
      }

      ssMessageChannel.current = new RTMClient();
      ssMessageChannel.current.init(appId);
      ssMessageChannel.current
        .login(agoraId.toString(), rtmToken)
        .then(() => {
          ssMessageChannel.current
          .joinChannel(rtmChannel)
          .then(() => {
            ssMessageChannel.current.channels[rtmChannel].joined = true;
            ssMessageChannel.current
              .sendChannelMessage(JSON.stringify({
                action: VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED,
                time: new Date().getTime(),
                screenSharingUserId : Number(userAgoraId)
              }), rtmChannel)
              .then(() => {
                console.error('message has been sent');
              })
              .catch((err) => {
                logger.error(err);
              });
          });
        });
    } catch (error) {
      
    }
  }

  const stopShareScreen = async (uuid) => {
    if (localShareTrackRef.current) {
      localShareTrackRef.current.stop();
      localShareTrackRef.current.close();
      localShareTrackRef.current.off("track-ended", () => {
        logger.error("track-ended off");
      });

      if (localShareAudioTrackRef.current) {
        localShareAudioTrackRef.current.stop();
        localShareAudioTrackRef.current.close();
        localShareAudioTrackRef.current.off("track-ended", () => {
          logger.error("track-ended off");
        });
        await screenShareClient.current.unpublish([
          localShareTrackRef.current,
          localShareAudioTrackRef.current,
        ]);
      } else {
        await screenShareClient.current.unpublish(localShareTrackRef.current);
      }

      localShareTrackRef.current = null;
      localShareAudioTrackRef.current = null;

      await screenShareClient.current.leave();
      screenShareClient.current = null;
      await ssMessageChannel.current.leaveChannel(rtmChannel);
      await ssMessageChannel.current.logout();
      ssMessageChannel.current = null;

      if (uuid) {
        clientMessageChannel
        .sendChannelMessage(JSON.stringify({
          action: VIDEO_COMMAND_TYPE.PRESENTATION_APPROVED,
          time: new Date().getTime()
        }), rtmChannel)
        .then(() => {
          console.error('message has been sent');
        })
        .catch((err) => {
          logger.error(err);
        });
      }
    }
  };

  const join = async (
    appid,
    channel,
    tokens,
    uid,
    configuration,
    channelList
  ) => {
    if (!client) return;
    try {
      const [microphoneTrack, cameraTrack] = await createLocalTracks(
        configuration
      );

      await client.join(appid, channel, tokens[channel], Number(uid));

      try {
        await client.enableDualStream();
      } catch (error) {
        logger.error(error);
      }

      await client.publish([microphoneTrack, cameraTrack]);

      AgoraRTC.getDevices(
        (devices) => {
          logger.info(devices);
        },
        (error) => {
          logger.error(error);
        }
      );

      setJoinState(true);

      channelList.forEachAsync(async (chnl) => {
        if (chnl.channelId !== channel) {
          const newClient = AgoraRTC.createClient({
            codec: VIDEO_PROPERTIES.CODEC,
            mode: VIDEO_PROPERTIES.MODE,
            role: STREAM_ROLES.AUDIENCE,
          });

          setListenners(newClient);
          await newClient.join(
            appid,
            chnl.channelId,
            tokens[chnl.channelId],
            Number(uid)
          );
          setOtherChannels(preState => ({
            ...preState,
            [chnl.channelId] : newClient
          }))
        }
      });

      if (process?.env.REACT_APP_PROJECT_ID === "vidiolo-dev-68f8f") {
        await testMultiUser(firebase, event?.eventPath, appid, batch || '0', createLocalTracks, configuration);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const leave = useCallback(async () => {
    if (localAudioTrack) {
      localAudioTrack.stop();
    }

    if (localVideoTrack) {
      localVideoTrack.stop();
    }

    if (window.videoTrack) {
      window.videoTrack.stop();
      window.videoTrack = null;
    }

    if (window.audioTrack) {
      window.audioTrack.stop();
      window.audioTrack = null;
    }

    setRemoteUsers([]);
    setJoinState(false);

    leaveChannels();

    window.location.href = "/events";
  }, [localAudioTrack, localVideoTrack, setRemoteUsers, setJoinState]);

  const leaveChannels = async () => {
    await client.leave();
    Object.keys(otherChannels).forEachAsync(async (channel) => {
      await otherChannels[channel].leave();
    });

    disposeListenner();
  };

  const setListenners = (currentClient) => {
    const handleUserPublished = async (user, mediaType) => {
      await currentClient.subscribe(user, mediaType);

      if (mediaType === 'video') {
        if (Number(meetingState.current.sharedId) === Number(user.uid)) {
          await currentClient.setRemoteVideoStreamType(
            Number(meetingState.current.sharedId),
            STREAM_TYPE.HIGH
          );
        } else {
          await currentClient.setRemoteVideoStreamType(
            Number(user.uid),
            STREAM_TYPE.LOW
          );
        }
  
      }
      await currentClient.setStreamFallbackOption(
        Number(user.uid),
        STREAM_FALLBACK_TYPE.AUDIO_ONLY
      );
      
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex > -1) {
          currentUsers[userIndex] = user;
        } else {
          currentUsers.push(user);
        }

        return currentUsers;
      });
    };

    const handleUserUnpublished = (user) => {
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex > -1) {
          currentUsers[userIndex] = user;
        }

        return currentUsers;
      });
    };

    const handleUserJoined = (user) => {
      setRemoteUsersStatus({
        joined: true,
        user,
      });
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex > -1) {
          currentUsers[userIndex] = user;
        } else {
          currentUsers.push(user);
        }

        return currentUsers;
      });
    };

    const handleUserLeft = (user) => {
      setRemoteUsersStatus({
        joined: false,
        user,
      });
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex !== -1) {
          currentUsers.splice(userIndex, 1);
        }

        return currentUsers;
      });
    };

    const handleErrorEvents = (error) => {
      logger.error(error);
    }

    currentClient.on("user-published", handleUserPublished);
    currentClient.on("user-unpublished", handleUserUnpublished);
    currentClient.on("user-joined", handleUserJoined);
    currentClient.on("user-left", handleUserLeft);
    currentClient.on("live-streaming-error", handleErrorEvents);
    currentClient.on("exception", handleErrorEvents);
  };

  const disposeListenner = () => {
    client.removeAllListeners();
    Object.keys(otherChannels).forEach((channel) => {
      otherChannels[channel].removeAllListeners();
    });
  };

  useEffect(() => {
    setRemoteUsers(client.remoteUsers);

    setListenners(client);

    return () => {
      disposeListenner();
    };
  }, []);

  return {
    localAudioTrack,
    localVideoTrack,
    localShareTrack : localShareTrackRef.current,
    joinState,
    remoteUsersStatus,
    leave,
    join,
    remoteUsers,
    playShareScreen,
    stopShareScreen,
    localShareAudiTrack,
    rtcError,
    setRTCError,
    updateHookMeetingState,
    setRemoteUsers,
    otherChannels
  };
};

export default useAgora;
