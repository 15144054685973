import { VIDEO_COMMAND_TYPE } from 'constants/video';
import React from 'react';
import VideoControlIcon from './VideoControlIcon';

const VideoHostControl = ({
  ownerAgoraId,
  sharedId,
  sendMessageToUser,
  isHost,
  sendMessageToChannel,
  cameraList
}) => (
  <div className="video-host-control">
    <VideoControlIcon
      onClick={(e) => {
        e.stopPropagation();
        sendMessageToChannel(
          {
            action: VIDEO_COMMAND_TYPE.REVOKE_PRESENTATION,
            targetId: Number(sharedId),
            time: new Date().getTime(),
          },
          Number(sharedId)
        );
      }}
      className={`video-control-btn ${Number(ownerAgoraId) === Number(sharedId) ? 'video-control-present-active' : ''}`}
      isOn={Number(ownerAgoraId) === Number(sharedId)}
      title="Present"
      onIcon={<i className="icon-Home" />}
      offIcon={<i className="icon-Home" />}
    />
    {cameraList.map((camera, index) => {
      return (
      <VideoControlIcon
        key={index}
        onClick={(e) => {
          e.stopPropagation();
          sendMessageToUser(
            {
              action: VIDEO_COMMAND_TYPE.PRESENT,
              targetId: Number(camera.user.ticketAgoraId),
              time: new Date().getTime(),
            },
            Number(camera.user.ticketAgoraId)
          );
        }}
        className={`video-control-btn ${Number(camera.user.ticketAgoraId) === Number(sharedId) ? 'video-control-present-active' : ''}`}
        isOn={Number(camera.user.ticketAgoraId) === Number(sharedId)}
        title="Present"
        label={camera.user.displayName?.split(' ')[1]}
        onIcon={<i className="icon-Camera" />}
        offIcon={<i className="icon-Camera" />}
      />
    )})}
  </div>
);

export default VideoHostControl;
