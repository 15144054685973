/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
import classNames from "classnames";
import * as Cookies from "js-cookie";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { compose } from "recompose";
import { withFirebase } from "../../service/firebase";
import moment from "moment";
import sortBy from "lodash/sortBy";
import CountDown from "../CountDown";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import {
  browserName,
  isFirefox,
  isEdge,
  isMSIE,
} from "../../helper/browser-detector";

import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  Info,
  Button,
  If,
  Snackbar,
  Badge
} from "../../common";

import productStyle from "../../assets/jss/material-kit-pro-react/views/productStyle";
import blogsStyle from "../../assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle";
import cardStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
import modalStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import CATEGORIES from "../../constants/category";
import { getDurationString } from "helper/time-helper";
import EventProfileStar from "components/Events/EventProfileStar";
import StripePayment from "components/Stripe/StripePayment";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(productStyle);
const blogUseStyles = makeStyles(blogsStyle);
const cardsUseStyles = makeStyles(cardStyle);
const modalUseStyles = makeStyles(modalStyle);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EventDetail = (props) => {
  const classes = useStyles();
  const blogClasses = blogUseStyles();
  const cardClasses = cardsUseStyles();
  const modalClasses = modalUseStyles();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [meetingStatus, setMeetingStatus] = useState(null);
  const [cameraList, setCameraList] = useState([]);
  const [userAgoraId, setUserAgoraId] = useState(null);
  const [meetingConfiguration, setMeetingConfiguration] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [paymentData, setPaymentData] = useState();
  const [payment, setPayment] = useState(false);
  const [initPayment, setInitPayment] = useState(false);

  useEffect(() => {
    const {
      match: { params },
    } = props;
    let subscribeAttendeEvents = null;
    let subscribe = null;
    setUserAgoraId(params.userAgoraId);

    const getEvent = (authUser) => {
      subscribe = props.firebase
        .event(params.ownerId, params.eventId)
        .onSnapshot(async (snap) => {
          const event = snap.data();
          setEventData(event);

          if (params.userAgoraId === "undefined" && authUser) {
            if (event.eventOwnerId === authUser?.uid) {
              setUserAgoraId(event.eventOwnerAgoraId);
            } else {
              subscribeAttendeEvents = props.firebase
                .events(authUser?.uid)
                .onSnapshot((snap) => {
                  const data = snap.docs
                    .map((doc) => doc.data())
                    .find((item) => item.event.eventId === event.eventId);
                  if (data) {
                    setUserAgoraId(data.ticketAgoraId);
                  }
                });
            }
          }

          const configuration = (
            await props.firebase.getConfiguration()
          ).data();
          setMeetingConfiguration(configuration);

          const serverTime = props.firebase.getTimeStamp();
          const isStarted =
            Number(serverTime.seconds) +
              Number(configuration?.waiting_lounge_duration_in_mins * 60) >
            Number(event?.eventDate?.seconds);
          const isCompleted =
            Number(serverTime.seconds) >
            Number(event?.eventDate?.seconds) + Number(event?.eventDuration);
          const showCounter =
            Number(serverTime.seconds) + 3600 >
            Number(event?.eventDate?.seconds);

          setMeetingStatus({
            isStarted,
            isCompleted,
            showCounter,
          });

          const currentCameraList = await props.firebase.getHostCameras(
            event.eventPath
          );
          setCameraList(
            currentCameraList.docs
              .map((camera) => {
                return {
                  ticketAgoraId: camera.data().ticketAgoraId,
                  displayName: camera.data().attendee.displayName,
                  isCamera: camera.data().ticketType !== 'SCREEN_SHARING',
                  isShareScreen: camera.data().ticketType === 'SCREEN_SHARING',
                };
              })
              .sort((a, b) => a.displayName - b.displayName)
          );
        });
    };

    const listener = props.firebase.onAuthUserListener(
      (authUser) => {
        getEvent(authUser);
        setCurrentUser(authUser);
      },
      () => {
        getEvent();
      }
    );

    return () => {
      listener();

      if (subscribe) {
        subscribe();
      }

      if (subscribeAttendeEvents) {
        subscribeAttendeEvents();
      }
    };
  }, []);

  const getMyChannel = (userAgoraId, channelList) => {
    let myChannel = null;
    channelList.forEach((channel) => {
      channel.agoraIds.forEach((agoraId) => {
        if (Number(agoraId) === Number(userAgoraId)) {
          myChannel = channel.channelId;
          return false;
        }
      });
    });

    return myChannel;
  };

  const getRtmChannel = (channelList) => {
    let rtmChannel = null;
    channelList.forEach((channel) => {
      if (channel.isPrimary) {
        rtmChannel = channel.channelId;
      }
    });

    if (!rtmChannel && channelList.length) {
      rtmChannel = channelList[0].channelId;
    }

    return rtmChannel;
  };

  const onJoin = useCallback(
    (passedUserAgoraId) => {
      const {
        match: { params },
      } = props;
      const { channelList, eventId } = eventData;
      setLoading(true); // channel is eventId
      const agoraToken = props.firebase.getAgoraToken();
      const currentAgoraId = passedUserAgoraId || userAgoraId;
      agoraToken({
        agoraUserId: currentAgoraId,
        channelIds: channelList.map((channel) => channel.channelId),
      })
        .then((result) => {
          Cookies.set("eventId", eventId);
          Cookies.set("channel", getMyChannel(currentAgoraId, channelList));
          Cookies.set("channelList", channelList);
          Cookies.set("rtmChannel", getRtmChannel(channelList));
          Cookies.set("userAgoraId", currentAgoraId);
          Cookies.set("tokens", result.data.rtcTokens);
          Cookies.set("rtmToken", result.data.rtmToken);
          Cookies.set("ownerId", params.ownerId);
          Cookies.set("batch", params.batch);
          props.history.push("/meeting");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    },
    [eventData, userAgoraId]
  );

  const onClaimFreeTicket = useCallback(() => {
    setLoading(true);
    const claimTicket = props.firebase.claimFreeTicket();

    claimTicket({
      userId: currentUser.userId,
      userImageUrl: currentUser.userImageUrl,
      userDisplayName: currentUser.displayName,
      eventPath: eventData.eventPath,
    })
      .then((result) => {
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error, "result error");
      });
  }, [eventData]);

  const getTitle = useCallback(
    (isHost) => {
      if (meetingStatus?.isStarted && !meetingStatus?.isCompleted) {
        return isHost ? "Join Controller & Camera" : "Join Event";
      } else {
        if (meetingStatus?.isCompleted) {
          return "Event is already done.";
        } else {
          return (
            <React.Fragment>
              <span style={{ paddingRight: "20px" }}>
                There is still time to start.
              </span>
              <If
                condition={Boolean(meetingStatus?.showCounter)}
                render={() => (
                  <CountDown
                    callback={() => {
                      const serverTime = props.firebase.getTimeStamp();
                      const isStarted =
                        Number(serverTime.seconds) +
                          Number(
                            meetingConfiguration?.waiting_lounge_duration_in_mins *
                              60
                          ) >
                        Number(eventData?.eventDate?.seconds);
                      const isCompleted =
                        Number(serverTime.seconds) >
                        Number(eventData?.eventDate?.seconds) +
                          Number(eventData?.eventDuration);
                      setMeetingStatus({
                        isStarted,
                        isCompleted,
                      });
                    }}
                    callbackCount={
                      meetingConfiguration?.waiting_lounge_duration_in_mins *
                        60 -
                      1
                    }
                    serverEndTime={Number(eventData?.eventDate?.seconds) * 1000}
                    serverNow={props.firebase.getTimeStamp().seconds * 1000}
                  />
                )}
              />
            </React.Fragment>
          );
        }
      }
    },
    [meetingConfiguration, eventData, meetingStatus, setMeetingStatus]
  );

  const handlePay = useCallback(async () => {
    setInitPayment(true);
    const ownerData = await props.firebase.user(eventData.eventOwnerId).get();
    const eventOwner = ownerData.data();
    try {
      if (
        eventOwner.stripeConnectedAccountType === "regular" &&
        eventOwner.stripeConnectedAccount?.charges_enabled &&
        eventOwner.stripeConnectedAccount?.payouts_enabled
      ) {
        const result = await props.firebase.createOrder(
          currentUser.userId,
          eventData.eventPath,
          1,
          "regular",
          eventOwner.stripeConnectedAccount?.id,
          eventData.eventPrice?.amount * 100
        );
        setPaymentData(result);
      } else if (
        eventOwner.stripeConnectedAccountType === "fallback" &&
        eventOwner.fallbackStripeConnectedAccountId
      ) {
        const result = await props.firebase.createOrder(
          currentUser.userId,
          eventData.eventPath,
          1,
          "fallback",
          eventOwner.fallbackStripeConnectedAccountId,
          eventData.eventPrice?.amount * 100
        );
        setPaymentData(result);
      } else {
        setPayment({
          error: {
            message:
              "An error accoured. This event is not available to purchase.",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [currentUser, eventData]);

  return (
    <div className={classes.productPage}>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classNames(classes.main)}>
            <Card plain className={blogClasses.card}>
              <Dialog
                classes={{
                  root: modalClasses.modalRoot,
                  paper: modalClasses.modal,
                }}
                open={Boolean(initPayment)}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                  setPaymentData(null);
                  setInitPayment(false);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={modalClasses.modalHeader}
                >
                  <Button
                    simple
                    className={modalClasses.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => {
                      setPaymentData(null);
                      setInitPayment(false);
                    }}
                  >
                    {" "}
                    <Close className={modalClasses.modalClose} />
                  </Button>
                  <h4 className={modalClasses.modalTitle}>Payment</h4>
                </DialogTitle>
                <DialogContent
                  id="classic-modal-slide-description"
                  className={modalClasses.modalBody}
                >
                  <If
                    condition={Boolean(paymentData)}
                    otherwise={() => (
                      <If
                        condition={payment}
                        otherwise={() => (
                          <React.Fragment>
                            <LinearProgress />
                            <p>Loading...</p>
                          </React.Fragment>
                        )}
                        render={() => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <If
                              condition={payment && payment !== "success"}
                              otherwise={() => (
                                <h3
                                  style={{ textAlign: "center" }}
                                  className={modalClasses.modalTitle}
                                >
                                  Payment Successful
                                </h3>
                              )}
                              render={() => (
                                <Snackbar
                                  message={
                                    <span>{payment.error?.message}</span>
                                  }
                                  color="danger"
                                  icon="info_outline"
                                />
                              )}
                            />
                            <Button
                              round
                              color="primary"
                              onClick={() => {
                                setPayment(null);
                                setInitPayment(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                        )}
                      />
                    )}
                    render={() => (
                      <React.Fragment>
                        <If
                          condition={payment && payment !== "success"}
                          render={() => (
                            <Snackbar
                              message={<span>{payment.error?.message}</span>}
                              color="danger"
                              icon="info_outline"
                            />
                          )}
                        />
                        <Elements
                          stripe={stripePromise}
                          options={{
                            clientSecret:
                              paymentData?.paymentIntent?.client_secret,
                          }}
                        >
                          <StripePayment
                            onSuccess={() => {
                              setPaymentData(null);
                              setPayment("success");
                            }}
                            onError={(error) => {
                              setPayment(error);
                            }}
                            handleOnSubmit={() => setPayment(null)}
                          />
                        </Elements>
                      </React.Fragment>
                    )}
                  />
                </DialogContent>
              </Dialog>
              <If
                condition={Boolean(eventData)}
                render={() => (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CardHeader image plain>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          <img src={eventData.eventImageUrl} alt="..." />
                        </a>
                        <If
                          condition={Boolean(eventData.isPrivate)}
                          render={() => (
                            <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                              <Badge
                                color="primary"
                                className="event-item-badge"
                              >
                                INVITATION ONLY
                              </Badge>
                            </div>
                          )}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <Info>
                        <h6 className={blogClasses.cardCategory}>
                          {eventData.eventCategories
                            .map((category) => CATEGORIES[category].title)
                            .join(", ")}
                        </h6>
                      </Info>
                      <h3 className={blogClasses.cardTitle}>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          {eventData.eventTitle}
                        </a>
                      </h3>
                      <div className={cardClasses.author}>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          <img
                            src={eventData.eventOwnerImageUrl}
                            alt={eventData.eventOwnerDisplayName}
                            className={
                              cardClasses.imgRaised + " " + cardClasses.avatar
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: "5px",
                            }}
                          >
                            <strong
                              style={{
                                marginLeft: "5px",
                                lineHeight: "15px",
                              }}
                            >
                              {eventData.eventOwnerDisplayName}
                            </strong>
                            <EventProfileStar
                              starCount={eventData.eventOwnerRating}
                            />
                          </div>
                        </a>
                      </div>
                      <p
                        className={blogClasses.description}
                        style={{ paddingTop: "20px" }}
                      >
                        {eventData.eventDescription}
                      </p>
                      <hr />
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Date"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {moment(eventData.eventDate.toDate()).format(
                              "ddd, DD MMM"
                            )}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Clock"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {moment(eventData.eventDate.toDate()).format(
                              "hh:mm A"
                            )}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Duration"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {getDurationString(eventData.eventDuration)}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Language"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            English
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-CreditCard"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {eventData.isFree
                              ? "FREE"
                              : `${eventData.eventPrice.amount} ${eventData.eventPrice.currency}`
                              }
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Seats"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {eventData.eventSeatsTotal} Seats
                          </span>
                        </GridItem>
                      </GridContainer>
                      <hr />
                      <If
                        condition={
                          !isFirefox && !isEdge && !isMSIE
                        }
                        otherwise={() => (
                          <Snackbar
                            message={
                              <span>
                                {`We do not support ${browserName} browser. Please try an another browser. (Chrome, Opera, Safari)`}
                              </span>
                            }
                            color="danger"
                            icon="info_outline"
                          />
                        )}
                        render={() => (
                          <If
                            condition={loading}
                            otherwise={() => (
                              <If
                                condition={
                                  Number(eventData.eventOwnerAgoraId) ===
                                  Number(userAgoraId)
                                }
                                otherwise={() => (
                                  <If
                                    condition={userAgoraId !== "undefined"}
                                    otherwise={() => (
                                      <If
                                        condition={Boolean(
                                          eventData.isFree && currentUser
                                        )}
                                        otherwise={() => (
                                          <If
                                            condition={Boolean(currentUser)}
                                            otherwise={() => (
                                              <Button
                                                round
                                                color="primary"
                                                href="/login"
                                              >
                                                Login to buy ticket
                                              </Button>
                                            )}
                                            render={() => (
                                              <Button
                                                round
                                                color="primary"
                                                onClick={() => handlePay()}
                                              >
                                                Buy Ticket
                                              </Button>
                                            )}
                                          />
                                        )}
                                        render={() => (
                                          <Button
                                            round
                                            color="primary"
                                            onClick={() => onClaimFreeTicket()}
                                          >
                                            Claim Free Ticket
                                          </Button>
                                        )}
                                      />
                                    )}
                                    render={() => (
                                      <Button
                                        round
                                        color={
                                          meetingStatus?.isStarted &&
                                          !meetingStatus?.isCompleted
                                            ? "primary"
                                            : meetingStatus?.isCompleted
                                            ? "danger"
                                            : "rose"
                                        }
                                        disabled={
                                          !meetingStatus?.isStarted ||
                                          meetingStatus?.isCompleted
                                        }
                                        onClick={() => onJoin()}
                                      >
                                        {getTitle()}
                                      </Button>
                                    )}
                                  />
                                )}
                                render={() => (
                                  <React.Fragment>
                                    <Button
                                      round
                                      color={
                                        meetingStatus?.isStarted &&
                                        !meetingStatus?.isCompleted
                                          ? "primary"
                                          : meetingStatus?.isCompleted
                                          ? "danger"
                                          : "rose"
                                      }
                                      disabled={
                                        !meetingStatus?.isStarted ||
                                        meetingStatus?.isCompleted
                                      }
                                      onClick={() => onJoin()}
                                    >
                                      {getTitle(true)}
                                    </Button>
                                    <hr />
                                    <If
                                      condition={Boolean(
                                        meetingStatus?.isStarted &&
                                          !meetingStatus?.isCompleted
                                      )}
                                      render={() =>
                                        sortBy(cameraList?.filter(item => item.isCamera), ["displayName"]).map(
                                          (camera) => (
                                            <Button
                                              key={camera.ticketAgoraId}
                                              round
                                              color={
                                                meetingStatus?.isStarted &&
                                                !meetingStatus?.isCompleted
                                                  ? "primary"
                                                  : meetingStatus?.isCompleted
                                                  ? "danger"
                                                  : "rose"
                                              }
                                              disabled={
                                                !meetingStatus?.isStarted ||
                                                meetingStatus?.isCompleted
                                              }
                                              onClick={() =>
                                                onJoin(camera.ticketAgoraId)
                                              }
                                            >
                                              {camera.displayName}
                                            </Button>
                                          )
                                        )
                                      }
                                    />
                                  </React.Fragment>
                                )}
                              />
                            )}
                            render={() => (
                              <React.Fragment>
                                <LinearProgress />
                                <p>Connecting...</p>
                              </React.Fragment>
                            )}
                          />
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withFirebase)(EventDetail);
