import React from "react";
import { func, node, string, bool } from "prop-types";
import { Button } from "common";
import {ReactComponent as LoadingIcon} from '../../assets/img/loading-icon.svg';

const VideoControlIcon = ({
  isOn,
  title,
  className,
  onClick,
  onIcon,
  offIcon,
  isWaiting,
  disabled,
  label
}) => (
  <Button
    color="videoAction"
    round
    justIcon
    className={className}
    disabled={disabled}
    title={`${isOn ? "Disable" : "Enable"} ${title}`}
    onClick={(e) => !disabled && onClick(e)}
    onMouseDown={(e) => e.preventDefault()}
  >
    {label && (<div className={`video-control-btn-label ${isOn && !disabled ? 'active' : ''}`}>{label}</div>)}
    {isWaiting ? (
      <LoadingIcon className="video-loading-icon" />
    ) : isOn && !disabled ? (
      onIcon
    ) : (
      offIcon
    )}
  </Button>
);

VideoControlIcon.propTypes = {
  onClick: func.isRequired,
  isOn: bool.isRequired,
  onIcon: node.isRequired,
  offIcon: node.isRequired,
  className: string.isRequired,
  title: string.isRequired,
};

export default VideoControlIcon;
