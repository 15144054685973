import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/messaging";

import { isSafari } from "../../helper/browser-detector";

const moment = require("moment");

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STOAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.currentUser = null;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.cloudFunctions = app.functions();
    this.messaging = !isSafari ? app.messaging() : null;
    this.userName = null;

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
    this.appleProvider = new app.auth.OAuthProvider("apple.com");
    this.appleProvider.addScope("email");
    this.appleProvider.addScope("name");

    this.askForPermissioToReceiveNotifications();
  }

  displayNameCreator(displayName, email) {
    if (!displayName) {
      return displayName;
    } else if (!email) {
      let name = [];
      let result = "";
      var pre = email.split("@")[0];
      name = pre?.split(".") ?? [""];

      for (let i = 0; i < name.length; i++) {
        name[i] =
          name[i].substring(0, 1).toUpperCase() +
          name[i].substring(1, name[i].length);
        result += name[i] + " ";
      }

      return result.trim();
    } else {
      return "";
    }
  }

  displayInitialsCreator(displayName, email) {
    var dN = this.displayNameCreator(displayName, email);
    var split = dN?.trim()?.split(" ") ?? [];
    var acronym = "";

    for (var i = 0; i < split.length; i++) {
      if (split[i].length > 0) {
        acronym += split[i][0];
      }

      if (acronym.length === 3) {
        break;
      }
    }

    return acronym;
  }

  getConfiguration = () => {
    return this.db.collection("server_config").doc("event").get();
  };

  getToken = () => {
    return this.messaging
      .getToken({
        vapidKey:
          "BHW9PmJJditlWQXX4z_64rDDeFSMFnSdoyd0WIlVGTecoobg8AG--mcCP6Ft0nFZ0vmmRQq4LSawC9EZa7OoFwU",
      })
      .then((currentToken) => {
        if (currentToken) {
          const hasToken = this.currentUser.tokens.indexOf(currentToken) !== -1;

          if (!hasToken) {
            const update = this.updateTokens();
            update({
              token: currentToken,
              userId: this.currentUser.userId,
            });
          }
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  };

  onMessageListener = () =>
    new Promise((resolve) => {
      this.messaging.onMessage((payload) => {
        resolve(payload);
      });
    });

  updateEventLog = async (
    userId,
    eventId,
    eventType,
    agoraUserId,
    targetUserId,
    targetAgoraUserId
  ) => {
    await this.db
      .collection("users")
      .doc(userId)
      .collection("events")
      .doc(eventId)
      .collection("eventLogs")
      .add({
        userId: userId,
        agoraUserId: agoraUserId,
        targetUserId: targetUserId,
        targetAgoraUserId: targetAgoraUserId || "",
        event: eventType,
        operationTime: this.getTimeStamp(),
      });
  };

  askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = app.messaging();

      await Notification.requestPermission();

      const token = await messaging.getToken();

      let enableForegroundNotification = true;
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);

        if (enableForegroundNotification) {
          var notificationTitle = payload.notification.title;
          var notificationOptions = {
            body: payload.notification.body,
          };
          navigator.serviceWorker.getRegistrations().then((registration) => {
            registration[0].showNotification(
              notificationTitle,
              notificationOptions
            );
          });
        }
      });

      console.log("user token: ", token);

      return token;
    } catch (error) {
      console.error(error);
    }
  };

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = async (username, email, password) => {
    this.userName = username;

    const newAccount = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    await this.auth.currentUser.sendEmailVerification();

    return newAccount;
  };

  doEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithApple = () => this.auth.signInWithPopup(this.appleProvider);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  doVerifyEmail = (actionCode, success, error) => {
    try {
      this.auth
        .checkActionCode(actionCode)
        .then((actionCodeResult) => {
          switch (actionCodeResult.operation) {
            case "VERIFY_EMAIL":
              this.auth
                .applyActionCode(actionCode)
                .then(() => {
                  success(actionCodeResult);
                })
                .catch(error);
              break;
            default:
              break;
          }
        })
        .catch(error);
    } catch (error) {
      console.error(error, "error");
    }
  };

  doCheckResetPassword = (actionCode, success, error) => {
    try {
      this.auth
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          success(email);
        })
        .catch(error);
    } catch (error) {
      console.error(error, "error");
    }
  };

  doResetPassword = (actionCode, password, success, error) => {
    try {
      this.auth
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          this.auth
            .confirmPasswordReset(actionCode, password)
            .then(() => {
              success(email);
            })
            .catch(error);
        })
        .catch(error);
    } catch (error) {
      console.error(error, "error");
    }
  };

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid).onSnapshot(async (snapshot) => {
          const dbUser = snapshot.data();
          if (dbUser) {
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            this.currentUser = authUser;

            next(authUser);
          } else {
            const createUser = this.createNewUser();
            const dI = this.displayInitialsCreator(
              authUser.displayName,
              authUser.email
            );
            var newUser = {
              displayName: authUser.displayName || this.userName,
              displayInitials: dI,
              email: authUser.email,
              firstName: null,
              lastName: null,
              userImageUrl: authUser.photoURL,
              isPro: false,
              isAnonymous: authUser.isAnonymous,
              emailVerified: authUser.emailVerified,
              categories: [],
              tokens: [],
              uid: authUser.uid,
              rating: 0.0,
              numRatings: 0,
              locale: "en_US",
              country: "US",
              language: "en",
              currency: "USD",
            };

            createUser({
              userId: authUser.uid,
              userData: newUser,
              platform: "React Web"
            });

            next(authUser);
          }
        });
      } else {
        if (fallback) {
          fallback();
        }
      }
    });

  // *** User API ***

  user = (userId) => this.db.collection("users").doc(userId);

  updateEmailVerify = async (email) => {
    const userRef = await this.db
      .collection("users")
      .where("email", "==", email)
      .get();

    if (userRef.docs.length > 0) {
      this.db
        .collection("users")
        .doc(userRef.docs[0].data().userId)
        .set({ emailVerified: true }, { merge: true })
        .then((data) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  updateVerifyByEmail = () =>
    this.cloudFunctions.httpsCallable("user-verifyUserEmailByEmail");

  userByAgoraId = (agoraId) =>
    this.db
      .collection("users")
      .where("agoraUserId", "==", Number(agoraId))
      .get();

  users = () => this.db.collection("users").get();

  // *** App API ***
  getFeatureEvents = async (onSuccess) => {
    await this.db
      .collectionGroup("events")
      .orderBy("eventDate")
      .where(
        "eventDate",
        ">=",
        app.firestore.Timestamp.fromDate(
          moment().subtract(12, "hours").toDate()
        )
      )
      .where("eventStatus", "==", "published")
      .onSnapshot((snap) => {
        const featureList = {};
        snap.docs.forEach((item) => {
          const currentEvent = item.data();
          if (currentEvent.eventCategories) {
            currentEvent.eventCategories.forEach(category => {
              if (category && currentEvent.eventCategories.indexOf(category) !== -1) {
                const currentEvents = featureList[category]
                  ? featureList[category].events
                  : [];
                featureList[category] = {
                  categoryKey: category,
                  events: [...currentEvents, currentEvent],
                };
              }
            })
          } else {
            const currentEvents = featureList["GENERAL"]
              ? featureList["GENERAL"].events
              : [];
            featureList["GENERAL"] = {
              categoryKey: "GENERAL",
              events: [...currentEvents, currentEvent],
            };
          }
        });

        onSuccess(featureList);
      });
  };

  events = (userId) =>
    this.db
      .collection("users")
      .doc(userId)
      .collection("boughtTickets")
      .where("attendee.id", "==", userId)
      .where("status", "in", ["CURRENT", "USED"])
      .where(
        "event.eventDate",
        ">=",
        app.firestore.Timestamp.fromDate(
          moment().subtract(12, "hours").toDate()
        )
      )
      .orderBy("event.eventDate");

  event = (userId, eventId) => this.db.doc(`users/${userId}/events/${eventId}`);

  eventUpdate = (userId, eventId, data) =>
    this.db.doc(`users/${userId}/events/${eventId}`).set(data, { merge: true });

  getAttendeeList = (userId, eventPath, onSuccess = () => undefined) => {
    this.db
      .collection("users")
      .doc(userId)
      .collection("issuedTickets")
      .where("event.eventPath", "==", eventPath)
      .where("attendee.id", "!=", null)
      .onSnapshot((snap) => {
        const data = snap.docs.map((doc) => doc.data());
        const attendeeList = [];
        data.forEach((attendeeData) => {
          if (
            attendeeData.attendee.id &&
            (attendeeData.assignment.status === "SELF" ||
              attendeeData.assignment.status === "ASSIGNED")
          ) {
            attendeeList.push({
              ...attendeeData.attendee,
              ticketAgoraId: attendeeData.ticketAgoraId,
            });
          }
        });
        onSuccess(attendeeList);
      });
  };

  getHostCameras = (eventPath) => {
    return this.db
      .doc(eventPath)
      .collection("hostTickets")
      .where("ticketType", "in", ["CAMERA", "SCREEN_SHARING"])
      .get();
  };

  getDataByPath = (path) => {
    return this.db.doc(path).get();
  };

  hostEvents = (userId) =>
    this.db
      .collection("users")
      .doc(userId)
      .collection("events")
      .where(
        "eventDate",
        ">=",
        app.firestore.Timestamp.fromDate(
          moment().subtract(12, "hours").toDate()
        )
      )
      .orderBy("eventDate");

  getTimeStamp = () => app.firestore.Timestamp.now();

  getAgoraToken = () =>
    this.cloudFunctions.httpsCallable("agora-generateAgoraAccessTokens");

  createStripeConnectedAccount = () =>
    this.cloudFunctions.httpsCallable("stripe-createStripeConnectedAccount");

  getStripeConnectedAccount = () =>
    this.cloudFunctions.httpsCallable("stripe-getStripeConnectedAccount");

  updateStripeConnectedAccount = () =>
    this.cloudFunctions.httpsCallable("stripe-updateStripeConnectedAccount");

  startRecording = () =>
    this.cloudFunctions.httpsCallable("agora-startRecording");

  updateRecording = () =>
    this.cloudFunctions.httpsCallable("agora-updateRecording");

  stopRecording = () =>
    this.cloudFunctions.httpsCallable("agora-stopRecording");

  getUserDynoLink = () => this.cloudFunctions.httpsCallable("user-getUserLink");

  getVideoCv = () => this.cloudFunctions.httpsCallable("videocv-getVideoCv");

  claimCampaignTicket = () =>
    this.cloudFunctions.httpsCallable("ticket-getCampaignTicket");

  claimFreeTicket = () =>
    this.cloudFunctions.httpsCallable("ticket-claimFreeTicket");

  claimAssignedTicket = () =>
    this.cloudFunctions.httpsCallable("ticket-acceptTicket");

  updateTokens = () =>
    this.cloudFunctions.httpsCallable("user-addFirebaseCloudMessagingToken");

  createNewUser = () => this.cloudFunctions.httpsCallable("user-createNewUser");

  getScreenShareInfo = () => this.cloudFunctions.httpsCallable("agora-getScreenSharingCredentials");

  getTestUserDataCall = () => this.cloudFunctions.httpsCallable("data-getTestUserDataCall");

  createOrder = async (
    userId,
    eventPath,
    quantity,
    stripeConnectedAccountType,
    stripeConnectedAccountId,
    stripeAmount
  ) => {
    const createStripeOrder = await this.cloudFunctions.httpsCallable(
      "stripe-createOrder"
    );

    const result = await createStripeOrder({
      userId,
      eventPath,
      quantity,
      stripeConnectedAccountType,
      stripeConnectedAccountId,
      stripeAmount,
    });

    if (!result?.data?.error) {
      return {
        orderId: result.data?.orderId,
        paymentIntentId: result.data?.paymentIntentId,
        paymentIntent: result.data?.paymentIntent,
        ephemeralKey: result.data?.ephemeralKey,
      };
    }

    return null;
  };

  sendWebLog = (data) => {
    const currentData = {
      ...data,
      userId: this.currentUser?.uid,
      href : window.location.href
    };

    return this.db
      .collection("web_logs")
      .add(currentData);
  }


  
}

export default Firebase;
