import AgoraRTC from "agora-rtc-sdk-ng";
import { STREAM_ROLES } from "constants/video";
import { VIDEO_PROPERTIES } from "constants/video";

const testMultiUser = async (firebase, eventPath, appid, batch, createLocalTracks, configuration) => {
  const getTestData = firebase.getTestUserDataCall();

  getTestData({
    eventPath,
    batch
  }).then(async data => {
    if (data && data.data && data.data.users && data.data.users.length) {
      const [microphoneTrack, cameraTrack] = await createLocalTracks(
        configuration
      );

      data.data.users.forEach((user, index) => {
        setTimeout(async () => {
          const newClient = AgoraRTC.createClient({
            codec: VIDEO_PROPERTIES.CODEC,
            mode: VIDEO_PROPERTIES.MODE,
            role: STREAM_ROLES.HOST,
          });
      
          await newClient.join(
            appid,
            user.broadCastChannel,
            user.rtcTokens[user.broadCastChannel],
            Number(user.ticketAgoraId)
          );
    
          await newClient.enableDualStream();
    
          await newClient.publish([microphoneTrack, cameraTrack]);
        }, 5000 * (index + 1))
      });
    }
  })
}

export {
  testMultiUser
}