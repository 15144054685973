import React, {useCallback, useState} from 'react';
import classnames from 'classnames';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import VideoControlIcon from './VideoControlIcon';
import { If, Button } from '../../common';
import { makeStyles } from "@material-ui/core/styles";
import MusicNote from "@material-ui/icons/MusicNote";
import MusicOff from "@material-ui/icons/MusicOff";
import SettingsIcon from "@material-ui/icons/Settings";

import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(basicsStyle);

const VideoControls = ({
  isHost,
  isHostJoined,
  locked,
  videoTrack,
  audioTrack,
  handlePresent,
  leave,
  isPresenting,
  streamStatus,
  setLocalStreamStatus,
  playShareScreen,
  stopShareScreen,
  localShareTrack,
  localShareAudiTrack,
  devices,
  sharedUser,
  handleSwitchDevice,
  selectedDevices,
  startRecording,
  stopRecording,
  recordingData,
  recordingLoading
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const classes = useStyles();
  const handleMic = useCallback(async () => {
    if (audioTrack && isHostJoined) {
      setLocalStreamStatus(false, streamStatus.audio);
    }
  }, [audioTrack, isHostJoined, streamStatus, setLocalStreamStatus]);

  const handleCamera = useCallback(async () => {
    if (videoTrack && isHostJoined) {
      setLocalStreamStatus(true, streamStatus.video);
    }
  }, [videoTrack, isHostJoined, streamStatus, setLocalStreamStatus]);

  return (
    <div id="video-control-btn-group" className="video-control-btn-group">
      <div className="video-control-btn-group-name">
      <div className={`device-modal ${showSettings ? 'active' : ''}`}>
        <label>Camera</label>
        <FormControl fullWidth className={classes.selectFormControl}>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={selectedDevices.selectedVideoInputs}
            onChange={(event) => {
                handleSwitchDevice('video', event.target.value);
                setShowSettings(false);
              }
            }
            inputProps={{
              name: "cameraSelect",
              id: "camera-select"
            }}
          >
            {devices && devices.videoInputs.map(item => (
              <MenuItem
                key={item.deviceId}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item.deviceId}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <label>Audio Input</label>
        <FormControl fullWidth className={classes.selectFormControl}>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={selectedDevices.selectedAudioInputs}
            onChange={(event) => {
                handleSwitchDevice('audio-input', event.target.value);
                setShowSettings(false);
              }
            }
            inputProps={{
              name: "audioInputSelect",
              id: "audio-input-select"
            }}
          >
            {devices && devices.audioInputs.map(item => (
              <MenuItem
                key={item.deviceId}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item.deviceId}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <label>Audio Output</label>
        <FormControl fullWidth className={classes.selectFormControl}>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={selectedDevices.selectedAudioOutputs}
            onChange={(event) => {
                handleSwitchDevice('audio-output', event.target.value);
                setShowSettings(false);
              }
            }
            inputProps={{
              name: "audioInputSelect",
              id: "audio-output-select"
            }}
          >
            {devices && devices.audioOutputs.map(item => (
              <MenuItem
                key={item.deviceId} 
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item.deviceId}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <div className="video-header-settings" onClick={() => setShowSettings(!showSettings)}>
          <SettingsIcon />
        </div>
        <div className="video-header-presenter-user">
          <span className="video-header-presenter-user-title">Presenting</span>
          <span className="video-header-presenter-user-name">{sharedUser && sharedUser.displayName}</span>
        </div>
      </div>
      <div className="video-control-btn-group-controls">
        <VideoControlIcon
          onClick={handleMic}
          isDisable={!isHostJoined || locked}
          className={classnames(
            'video-control-btn',
            {
              disabled: !isHostJoined || !streamStatus.audio,
              locked: locked
            }
          )}
          disabled={locked}
          isOn={streamStatus.audio}
          title="Audio"
          onIcon={<i className="icon-Microphone"/>}
          offIcon={<i className="icon-MicrophoneOff"/>}
        />
        <Button color="videoAction" round justIcon className="video-control-btn exit-btn" onClick={leave} title="Exit">
          <i className="icon-Exit" />
        </Button>
        <VideoControlIcon
          onClick={handleCamera}
          className={classnames(
            'video-control-btn',
            {
              disabled: !isHostJoined || !streamStatus.video,
              locked: locked
            }
          )}
          disabled={!isHostJoined}
          isOn={streamStatus.video}
          title="Video"
          onIcon={<i className="icon-Camera" />}
          offIcon={<i className="icon-CameraOff" />}
        />
        <If
          condition={Boolean(isPresenting || localShareTrack)}
          render={() => (
            <VideoControlIcon
              onClick={() => Boolean(localShareTrack) ? stopShareScreen() : playShareScreen()}
              className={classnames(
                'video-control-btn',
                {
                  sharing: Boolean(localShareTrack),
                  locked: locked
                }
              )}
              isOn={Boolean(localShareTrack)}
              title="Screen share"
              onIcon={<i className="icon-Present" />}
              offIcon={<i className="icon-Present" />}
            />
          )}
        />
        <If
          condition={Boolean(isHost)}
          render={() => (
            <VideoControlIcon
              onClick={() => Boolean(localShareAudiTrack) ? stopShareScreen() : playShareScreen(true)}
              className={classnames(
                'video-control-btn',
                {
                  sharing: Boolean(localShareAudiTrack),
                  locked: locked
                }
              )}
              isOn={Boolean(localShareAudiTrack)}
              title="Screen audio"
              onIcon={<MusicNote />}
              offIcon={<MusicOff />}
            />
          )}
        />
      </div>
      <div className="video-control-btn-group-actions">
      <If
        condition={isPresenting && !isHost}
        render={() => (
          <span
            onClick={handlePresent}
            className="video-control-text-btn"
            title="Stop Presentation"
          >
            Stop Presenting
          </span>
        )}
      />
      <If
        condition={isHost}
        render={() => (
          <span className="video-control-text-btn" onClick={() => !recordingLoading && (recordingData ? stopRecording() : startRecording())}>
            {recordingLoading ? 'Loading...' : recordingData ? 'Stop Recording' : 'Start Recording'}
          </span>
        )}
      />
      </div>
    </div>
  );
}

export default VideoControls;
